export const FONT_FAMILY_MAIN = 'Poppins';

export const BASE_COLORS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  PURPLE: {
    100: '#D2D0D7',
    200: '#BCB8C3',
    300: '#A6A0AF',
    400: '#8F889B',
    500: '#797087',
    600: '#635973',
    700: '#4C415F',
    800: '#36294B',
    900: '#201238',
  },
  NAVY: {
    100: '#D4D5DB',
    200: '#BFC0C9',
    300: '#A9ABB7',
    400: '#9496A6',
    500: '#7E8194',
    600: '#696C82',
    700: '#535770',
    800: '#3E425E',
    900: '#282D4D',
  },
  TEAL: {
    50: '#ABC0CB',
    100: '#86A5B4',
    200: '#6B8A99',
    300: '#527E8F',
    400: '#487482',
    500: '#3E636D',
    600: '#34535A',
    700: '#2B4449',
    800: '#2B4448',
    900: '#224549',
  },
  FUCHSIA: {
    50: '#F6E3EF',
    100: '#F1D7E8',
    200: '#E3AFD1',
    300: '#D688BA',
    400: '#C860A3',
    500: '#BA388C',
    600: '#982E73',
    700: '#8F2A6B',
    800: '#892C69',
    900: '#822561',
  },
  TULIP: {
    50: '#E0DFEE',
    100: '#C2BFDD',
    200: '#877CB8',
    300: '#8074B1',
    400: '#7B6BAA',
    500: '#7659A0',
    600: '#6F569D',
    700: '#6B4FA1',
    800: '#6547A4',
    900: '#5D3BAA',
  },
  GRAYS: {
    50: '#F6F5F8',
    100: '#EAEEF8',
    200: '#ECEEEF',
    300: '#E6E6E6',
    400: '#CED4DA',
    500: '#C4C4C4',
    600: '#5A5A5A',
    700: '#373A3C',
  },
  BLUES: {
    50: '#AEDAE2',
    100: '#A4D5DE',
    200: '#8BD2DC',
    300: '#7794A7',
    400: '#6E95A9',
    500: '#405870',
    600: '#395973',
    700: '#4F6AB5',
    800: '#445688',
    900: '#1B2A54',
    950: '#212529',
  },
  GREENS: {
    100: '#CEDFDA',
    200: '#5EA180',
    300: '#599987',
    400: '#578E77',
    500: '#528561',
    600: '#448867',
    700: '#407045',
    800: '#3B7157',
    900: '#056D4E',
  },
  MAROON: {
    100: '#E8D3DB',
    200: '#DCBEC9',
    300: '#D1A8B7',
    400: '#C693A5',
    500: '#BB7D93',
    600: '#B16881',
    700: '#A7536F',
    800: '#9D3F5E',
    900: '#932D4C',
  },
  ORANGE: {
    100: '#FAE2CF',
    200: '#F8D4B7',
    300: '#F7C69F',
    400: '#F5B888',
    500: '#F3AA71',
    600: '#F29C5C',
    700: '#F08F48',
    800: '#EF8139',
    900: '#EE742F',
  },
  PASTELS: {
    100: '#FDE3DC',
    110: '#FFDDB5',
    120: '#F5E6B9',
    130: '#F0ECF9',
    140: '#E1E6F4',
    150: '#e5fde8',
    160: '#fae2cf',
    170: '#f3eaf8',
    180: '#eaf6f8',
    190: '#e8d3db',
    200: '#d4d4d4',
    210: '#c6b6da',
    220: '#d2d0d7',
    230: '#ffeff4',
    240: '#d2e4ff',
    250: '#d9f6fb',
    260: '#d2deff',
    270: '#e2e4f6',
    280: '#e8d4db', // accessibility friendly
    290: '#ADB7D4',
    300: '#AEA9DB',
    310: '#A5BECA',
    320: '#FFDEC5',
  },
  MISC: {
    DULL_RED: '#B65050',
    ORANGE: '#D7743F',
    MUSTARD: '#BD8C00',
    LILAC: '#654D96',
    DUSTY_BLUE: '#536188',
    RED: '#B13535',
    RUST: '#9E402B',
    BROWN: '#3F3110',
    TULIP: '#573897',
    GRADIENT_1: '#B01EFF',
    GRADIENT_2: '#6B86D2',
    GRADIENT_3: '#50659E',
    GRADIENT_4: '#77BA9A',
    GRADIENT_5: '#5FA281',
  },
};

export const COLORS = {
  MISC: {
    STEPS: BASE_COLORS.BLUES[300], // #7794A7
    TABLE_HOVER: BASE_COLORS.PURPLE[700], // #4C415F
    TAB_HOVER: BASE_COLORS.NAVY[700], // #535770
  },
  CORE: {
    // primary, danger, success, warning, link, light, dark, and none are all variants in bootstrap
    WHITE: BASE_COLORS.WHITE,
    BLACK: BASE_COLORS.BLACK,
    PRIMARY: BASE_COLORS.PURPLE[900], // #201238
    DANGER: BASE_COLORS.MAROON[900], // #932D4C
    SUCCESS: {
      100: BASE_COLORS.GREENS[600], // #448867
      DEFAULT: BASE_COLORS.GREENS[700], // #407045
    },
    WARNING: BASE_COLORS.ORANGE[900], // #EE742F
    LINK: BASE_COLORS.BLUES[800], // #445688
    MULTIVALUE_BACKGROUND: BASE_COLORS.GRAYS[100], // #EAEEF8
    DROPDOWN_FONT: BASE_COLORS.GRAYS[700], // #373A3C
    MID_GRAY: BASE_COLORS.GRAYS[300], // #E6E6E6
    DROPDOWN_BORDER: BASE_COLORS.GRAYS[400], // #CED4DA
    GRAY: BASE_COLORS.GRAYS[500], // #C4C4C4
    DARK_GRAY: BASE_COLORS.GRAYS[600], // #5A5A5A
    DARKEST_GRAY: BASE_COLORS.GRAYS[700], // #373A3C
    DANGER_ACCESSIBLE: BASE_COLORS.PASTELS[280], // '#e8d4db'
  },
  VARIANTS: {
    LIGHTER_GREY: BASE_COLORS.GRAYS[200], // #ECEEEF
    IMPLEMENTED: BASE_COLORS.GREENS[200], // #5ea180
    INFO: BASE_COLORS.BLUES[200], // #8bd2dc
    OUTDATED: BASE_COLORS.PASTELS[100], // #FDE3DC
    UPDATED: BASE_COLORS.GREENS[100], // #CEDFDA
    DEFAULT: BASE_COLORS.BLUES[950], // #212529
    PASS: BASE_COLORS.GREENS[600], // #448867
    TAG: BASE_COLORS.GRAYS[100], // #EAEEF8
    TAG_OUTLINE: BASE_COLORS.BLUES[800], // #445688
    BAR_0: BASE_COLORS.BLUES[500], // #405870
    BAR_1: BASE_COLORS.BLUES[400], // #6e95a9
    BAR_2: BASE_COLORS.PURPLE[900], // #201238
    BAR_3: BASE_COLORS.TULIP[300], // #8074B1
    UNKNOWN: BASE_COLORS.BLUES[800], // #445688
    BANNER: BASE_COLORS.ORANGE[200], // #F8D4B7
    PROGRESS: BASE_COLORS.GREENS[500], // #528561
  },
  AVATAR_BACKGROUND: {
    AVATAR_ORANGE: BASE_COLORS.PASTELS[160], // #FAE2CF
    AVATAR_PINK: BASE_COLORS.FUCHSIA[100], // #F1D7E8
    AVATAR_PURPLE: BASE_COLORS.NAVY[100], // #D4D5DB
    AVATAR_BLUE: BASE_COLORS.PASTELS[290], // #ADB7D4
    AVATAR_TURQUOISE: BASE_COLORS.PASTELS[190], // #e8d3db
  },
  REPORTS: {
    ADMISSION: BASE_COLORS.PASTELS[150], // #e5fde8
    GOLDILOCKS: BASE_COLORS.PASTELS[160], // #fae2cf
    KUBE_BENCH: BASE_COLORS.PASTELS[170], // #f3eaf8
    KUBE_HUNTER: BASE_COLORS.PASTELS[180], // #eaf6f8
    NOVA: BASE_COLORS.PASTELS[190], // #e8d3db
    OPA: BASE_COLORS.PASTELS[200], // #d4d4d4
    PLUTO: BASE_COLORS.PASTELS[210], // #c6b6da
    POLARIS: BASE_COLORS.PASTELS[220], // #d2d0d7
    RBAC_REPORTER: BASE_COLORS.PASTELS[230], // #ffeff4
    PROMETHEUS_METRICS: BASE_COLORS.PASTELS[240], // #d2e4ff
    TRIVY: BASE_COLORS.PASTELS[250], // #d9f6fb
    WORKLOADS: BASE_COLORS.PASTELS[260], // #d2deff
    KUBESEC: BASE_COLORS.PASTELS[270], // #e2e4f6
    CUSTOM_REPORTS: BASE_COLORS.FUCHSIA[100], // #F1D7E8,
    RIGHT_SIZER: BASE_COLORS.PASTELS[290], // #ADB7D4
    FALCO: BASE_COLORS.PASTELS[300], // #AEA9DB,
    RESOURCE_METRICS: BASE_COLORS.PASTELS[310], // #A5BECA
    KYVERNO: BASE_COLORS.PASTELS[320], // #FFDEC5
  },
  SEVERITY: {
    CRITICAL: BASE_COLORS.MISC.DULL_RED, // '#B65050'
    HIGH: BASE_COLORS.MISC.ORANGE, // '#D7743F'
    MEDIUM: BASE_COLORS.MISC.MUSTARD, // '#BD8C00'
    LOW: BASE_COLORS.MISC.LILAC, // '#654D96'
    NONE: BASE_COLORS.MISC.DUSTY_BLUE, // '#536188'
    PASSING: BASE_COLORS.GREENS[800], // #3B7157
  },
  SEVERITY_BADGES: {
    TEXT: {
      CRITICAL: BASE_COLORS.MISC.RED, // '#B13535'
      HIGH: BASE_COLORS.MISC.RUST, // '#9E402B'
      MEDIUM: BASE_COLORS.MISC.BROWN, // '#3F3110'
      LOW: BASE_COLORS.MISC.TULIP, // '#573897'
      NONE: BASE_COLORS.BLUES[900], // '#1B2A54'
      PASSING: BASE_COLORS.GREENS[900], // #056D4E
      INSTALLED: BASE_COLORS.GRAYS[700], // #373A3C
    },
    BACKGROUND: {
      CRITICAL: BASE_COLORS.PASTELS[100], // #FDE3DC
      HIGH: BASE_COLORS.PASTELS[110], // #FFDDB5
      MEDIUM: BASE_COLORS.PASTELS[120], // #F5E6B9
      LOW: BASE_COLORS.PASTELS[130], // #F0ECF9
      NONE: BASE_COLORS.PASTELS[140], // #E1E6F4
      PASSING: BASE_COLORS.GREENS[100], // #CEDFDA
      INSTALLED: BASE_COLORS.GRAYS[200], // #ECEEEF
    },
  },
  CHARTS: {
    SELECT_FILTER_PRIMARY: BASE_COLORS.PURPLE[600], // #635973
    TIMELINE: {
      FIXED: BASE_COLORS.GREENS[600], // #448867,
      NONE: BASE_COLORS.MISC.DUSTY_BLUE, // '#536188'
      LOW: BASE_COLORS.MISC.LILAC, // '#654D96'
      MEDIUM: BASE_COLORS.MISC.MUSTARD, // #BD8C00
      HIGH: BASE_COLORS.MISC.ORANGE, // #D7743F
      CRITICAL: BASE_COLORS.MISC.DULL_RED, // #B65050
    },
    BLUE: {
      100: BASE_COLORS.NAVY[500],
      200: BASE_COLORS.NAVY[600],
      300: BASE_COLORS.NAVY[700],
      400: BASE_COLORS.NAVY[800],
      500: BASE_COLORS.NAVY[900],
    },
    PURPLE: {
      100: BASE_COLORS.PURPLE[500],
      200: BASE_COLORS.PURPLE[600],
      300: BASE_COLORS.PURPLE[700],
      400: BASE_COLORS.PURPLE[800],
      500: BASE_COLORS.PURPLE[900],
    },
    LINE: {
      GREAT: BASE_COLORS.GREENS[700], // #407045
      GOOD: BASE_COLORS.GREENS[300], // #599987
      FINE: BASE_COLORS.BLUES[700], // #4F6AB5
      BAD: BASE_COLORS.MAROON[600], // #B16881
      DANGER: BASE_COLORS.MAROON[900], // #932D4C
    },
    DOTS: {
      GREAT: BASE_COLORS.GREENS[600], // #448867
      GOOD: BASE_COLORS.GREENS[500], // #528561
      FINE: BASE_COLORS.BLUES[800], // #445688
      BAD: BASE_COLORS.MAROON[500], // #BB7D93
      DANGER: BASE_COLORS.MAROON[800], // #9D3F5E
    },
    HEXBIN: {
      BORDER_DANGER: BASE_COLORS.MAROON[900], // #932D4C
      FILL_DANGER: BASE_COLORS.MAROON[400], // #C693A5
      BORDER_FINE: BASE_COLORS.BLUES[800], // #445688
      FILL_FINE: BASE_COLORS.TEAL[100], // #86A5B4
      BORDER_GOOD: BASE_COLORS.GREENS[700], // #407045
      FILL_GOOD: BASE_COLORS.GREENS[300], // #599987
    },
    COSTS_BAR: {
      BLUE: BASE_COLORS.BLUES[700], // #4f6ab5
      PURPLE: BASE_COLORS.TULIP[700], // #6B4FA1
      PALE_BLUE: BASE_COLORS.BLUES[100], // #A4D5DE
    },
    USAGE_LINE: {
      MAX: BASE_COLORS.MAROON[900], // #932D4C
      MIN: BASE_COLORS.TULIP[600], // #6F569D
      AVG: BASE_COLORS.MISC.ORANGE, // #D7743F
      LIMITS: BASE_COLORS.GREENS[600], // #448867
      SETTINGS_LIMITS: BASE_COLORS.BLUES[800], // #445688
      RECOMMENDED_FILL: BASE_COLORS.GREENS[300], // #599987
      ACTUAL_FILL: BASE_COLORS.GRAYS[100], // #EAEEF8
    },
    PACKAGES: {
      100: BASE_COLORS.TULIP[900], // #5D3BAA
      200: BASE_COLORS.TULIP[300], // #8074B1
    },
    WORKLOADS: {
      100: BASE_COLORS.TEAL[900], // #224549
      200: BASE_COLORS.TEAL[700], // #2B4449
      300: BASE_COLORS.TEAL[500], // #3E636D
      400: BASE_COLORS.TEAL[300], // #527E8F
      500: BASE_COLORS.TEAL[100], // #86A5B4
    },
    NAMESPACE: {
      100: BASE_COLORS.FUCHSIA[900], // '#822561'
      200: BASE_COLORS.FUCHSIA[600], // #982E73
    },
    CAPACITY: {
      100: BASE_COLORS.BLUES[400], // #6E95A9
      110: BASE_COLORS.TEAL[900], // #224549
      120: BASE_COLORS.TULIP[500], // #7659A0
      130: BASE_COLORS.TULIP[300], // #8074B1
      140: BASE_COLORS.GREENS[600], // #448867
      150: BASE_COLORS.FUCHSIA[500], // #BA388C
      160: BASE_COLORS.ORANGE[600], // #F29C5C
      170: BASE_COLORS.FUCHSIA[600], // #982E73
      180: BASE_COLORS.TULIP[400], // #7B6BAA
      190: BASE_COLORS.TEAL[800], // #2B4448
      200: BASE_COLORS.BLUES[500], // #405870
      210: BASE_COLORS.GREENS[300], // #599987
      220: BASE_COLORS.GREENS[500], // #528561
      230: BASE_COLORS.TEAL[400], // #487482
      240: BASE_COLORS.TEAL[300], // #527E8F
      250: BASE_COLORS.BLUES[600], // #395973
      260: BASE_COLORS.BLUES[700], // #4F6AB5
      270: BASE_COLORS.PURPLE[800], // #36294B
      280: BASE_COLORS.PURPLE[900], // #201238
      290: BASE_COLORS.GREENS[400], // #578E77
      300: BASE_COLORS.GREENS[700], // #407045
    },
    BULLET: {
      RANGE_1: BASE_COLORS.NAVY[900], // #282D4D
      RANGE_2: BASE_COLORS.BLUES[700], // #4F6AB5
      RANGE_3: BASE_COLORS.GRAYS[50], // #F6F5F8
      MEASURE: BASE_COLORS.GRAYS[500], // #C4C4C4
    },
    COSTS_DONUT: {
      IDLE: BASE_COLORS.TULIP[700], // #6B4FA1
      FILTERED: BASE_COLORS.TEAL[300], // #527E8F
      OTHER: BASE_COLORS.BLUES[800], // #445688
      FEE: BASE_COLORS.GREENS[600], // #448867
    },
    ACTION_ITEMS_REPORTS: {
      OPEN: BASE_COLORS.TULIP[700],
      INTRODUCED: BASE_COLORS.ORANGE[900],
      RESOLVED: BASE_COLORS.TEAL[700],
      FIXED: BASE_COLORS.GREENS[500],
      DELETED: BASE_COLORS.MAROON[900],
    },
    ACTION_ITEMS_SUMMARY: {
      OPEN: BASE_COLORS.TEAL[500],
      RESOLVED: BASE_COLORS.TULIP[700],
      FIXED: BASE_COLORS.GREENS[500],
    },
  },
  GRADIENTS: {
    PRIMARY: `linear-gradient(180deg, ${BASE_COLORS.PURPLE[700]} 0%, ${BASE_COLORS.PURPLE[800]} 14.06%, ${BASE_COLORS.PURPLE[900]} 100%)`,
    BRIGHT_PURPLE: `linear-gradient(180deg, ${BASE_COLORS.PURPLE[900]} 0%, ${BASE_COLORS.MISC.GRADIENT_1} 100%)`,
    BLUE: `linear-gradient(180deg, ${BASE_COLORS.MISC.GRADIENT_2} 0%, ${BASE_COLORS.MISC.GRADIENT_3} 42.27%, ${BASE_COLORS.LINK} 100%)`,
    GREEN: `linear-gradient(180deg, ${BASE_COLORS.MISC.GRADIENT_4} 0%, ${BASE_COLORS.MISC.GRADIENT_5} 43.75%, ${BASE_COLORS.GREENS[600]} 100%)`,
    WHITE: `linear-gradient(
      90deg,
      rgba(${BASE_COLORS.WHITE}, 0) 0,
      rgba(${BASE_COLORS.WHITE}, 0.2) 20%,
      rgba(${BASE_COLORS.WHITE}, 0.5) 60%,
      rgba(${BASE_COLORS.WHITE}, 0)
    )`,
  },
  SHADOWS: {
    CARD: `0.1px 0.1px 2px rgba(${BASE_COLORS.BLACK}, 0.25)`,
    MENU: `0 5px 5px -3px rgba(${BASE_COLORS.BLACK}, 0.2), 0 8px 10px 1px rgba(${BASE_COLORS.BLACK}, 0.14),
        0 3px 14px 2px rgba(${BASE_COLORS.BLACK}, 0.12) !important`,
    CONTEXT_MENU: `0.10000000149011612px 0.10000000149011612px 2px 0 ${BASE_COLORS.BLACK}40`,
    SYNCED_GOOD: `0px 0px 2px 0.1px ${BASE_COLORS.GREENS[400]}`,
    SYNCED_FAILED: `0px 0px 2px 0.1px ${BASE_COLORS.MAROON[900]}`,
    SYNCED_LOADING: `0px 0px 2px 0.1px ${BASE_COLORS.ORANGE[900]}`,
    TABLE_CARD_HEADER: `0 0 1px 0 ${BASE_COLORS.BLACK}1a`,
  },
  BORDER: {
    SOLID_LINK: `1px solid ${BASE_COLORS.BLUES[800]}`,
    THICK_LINK: `2px solid ${BASE_COLORS.BLUES[800]}`,
    SOLID_SUCCESS: `1px solid ${BASE_COLORS.GREENS[600]}`,
    DASHED_DEFAULT: `1px dashed ${BASE_COLORS.BLACK}`,
    LOADING_PRIMARY: `0.25em solid ${BASE_COLORS.PURPLE[900]}`,
    THICK_PRIMARY: `0.5em solid ${BASE_COLORS.PURPLE[900]}`,
    SOLID_PRIMARY: `1px solid ${BASE_COLORS.PURPLE[900]}`,
    SELECT_BORDER: `1px solid ${BASE_COLORS.GRAYS[600]}`,
    SOLID_TABLE: `1px solid ${BASE_COLORS.GRAYS[500]}`,
    TABLE_HEAD_THICK_BOTTOM: `2px solid ${BASE_COLORS.GRAYS[500]}`,
    TABLE_HEAD_TOP: `1px solid ${BASE_COLORS.PURPLE[100]}`,
    CONTEXT_MENU: `1px solid ${BASE_COLORS.GRAYS[400]}`,
    TABLE_HEADER: `1px solid ${BASE_COLORS.GRAYS[200]}`,
    CARD_BORDER: `1px solid rgba(${BASE_COLORS.BLACK}, 0.125)`,
    DANGER: `1px solid ${BASE_COLORS.MAROON[900]}`,
  },
};
