// eslint-disable-next-line
export const strings = {
  Fairwinds: 'Fairwinds',
  SummaryOfNodes: 'Summary of nodes',
  Name: 'Name',
  Role: 'Role',
  InstanceType: 'Instance Type',
  Requested: 'Requested',
  jira: 'Jira',
  snooze: 'snooze',
  github: 'GitHub',
  azure: 'Azure',
  workloads: 'workloads',
  circleCI: 'Circle CI',
  travisCI: 'Travis CI',
  githubActions: 'GitHub Actions',
  gitlab: 'Gitlab',
  jenkins: 'Jenkins',
  azureDevOps: 'Azure DevOps',
  Status: 'Status',
  percentage: 'percentage',
  cncf: 'CNCF',
  goldilocks: 'Goldilocks',
  nova: 'Nova',
  pluto: 'Pluto',
  polaris: 'Polaris',
  bug: 'bug',
  List: 'List',
  userRole: 'user role',
  Cancel: 'Cancel',
  Unlink: 'Unlink',
  Remove: 'Remove',
  removed: 'removed',
  Delete: 'Delete',
  Enable: 'Enable',
  Error: 'Error',
  Logging: 'Logging',
  Logs: 'Logs',
  created: 'created',
  Update: 'Update',
  updated: 'updated',
  Download: 'Download',
  added: 'added',
  installed: 'installed',
  custom: 'custom',
  average: 'average',
  Efficiency: 'Efficiency',
  Reliability: 'Reliability',
  Security: 'Security',
  runningReports: 'running reports',
  nodesL30D: 'nodes l30D',
  policiesSet: 'policies set',
  rulesSet: 'rules set',
  checkType: 'check type',
  sorry: 'Sorry',
  Table: 'Table',
  Newest: 'Newest',
  Outdated: 'Outdated',
  Deprecated: 'Deprecated',
  helmVersion: 'Helm Version',
  noNodesToDisplay: 'Sorry, it looks like you have no nodes to display',
  noCheckTypes: 'looks like there are no checks for your standard',
  updateAgent: 'Update Agent',
  zero$: '$0',
  noCostData: 'No Cost Data',
  access: 'Access',
  agent: 'agent',
  admission: 'admission',
  prometheusMetrics: 'prometheus-metrics',
  prometheus: 'prometheus',
  healthScore: 'health score',
  actionItems: 'action items',
  trends: 'trends',
  takeAssessment: 'take assessment',
  healthScoreDescription: 'Health score is the ratio of passing to failing Action Items, weighted by severity',
  healthScoreEmpty: 'Install the Agent to see your current health score',
  setupInsights: 'set up insights',
  completeNextStep: 'complete next step',
  stepsCompleted: '4 steps completed',
  checksCompleted: 'Checks Completed',
  overallProgress: 'overall progress',
  seeDocumentation: 'See Documentation',
  resultsPerPage: 'Results per page',
  manageIntegration: 'Manage Integrations Settings',
  ticketCreationError: 'We had an issue creating a ticket for this Action Item.',
  listTicketCreationError: 'we were unable to create your list ticket.',
  listTicket: 'List ticket',
  noGithubLabels: 'we were unable to retrieve your GitHub labels.',
  noProjects: 'we were unable to retrieve your projects.',
  firstSeen: 'First Seen',
  createdAt: 'Created At',
  lastReported: 'Last Reported',
  updatedAt: 'Updated At',
  dateReported: 'Date Reported',
  user: 'user',
  latestActivity: 'Latest Activity',
  confirmOrgDeletion: 'Confirm Deletion of Organization',
  deleteOrg: 'Delete Organization',
  deleteOrgExplanation1: 'This will remove the Organization orgName from your account. Please contact ',
  deleteOrgExplanation2: 'if you would like to permanently delete your data.',
  notificationPreferences: 'Update notification frequency preferences',
  realTime: 'Real Time',
  dailyDigest: 'Daily Digest',
  confirmRepoDelete: 'Confirm deletion of Repository',
  confirmRepoDeleteExplanation: 'This will remove the Repository selectedRepo from your organization.',
  noRepos: 'There are no repositories',
  metaTitle: 'Fairwinds Insights | ',
  resolution: 'resolution',
  AdmissionRequests: 'admission-requests',
  AdmissionRequestsCluster: 'admission-requests-cluster',
  Login: 'Login',
  Logout: 'Logout',
  termsOfServices: 'Terms of Services Update',
  Register: 'Register',
  profileCompletion: 'Profile Completion',
  confirmAccount: 'Confirm Account',
  accountCreated: 'Account Created',
  createNewOrg: 'Create New Organization',
  passwordRecovery: 'Password Recovery',
  myOrgs: 'My Organizations',
  Home: 'Home',
  Docs: 'Docs',
  repoBranch: 'Repository Branch',
  complianceReport: 'Compliance Report',
  createNewCluster: 'Create New Cluster',
  Announcement: 'Announcement',
  imageVulns: 'Image Vulnerabilities',
  allImageVulns: 'All Image Vulnerabilities',
  imageVulnsDetails: 'Image Vulnerabilities Details',
  Policies: 'Policies',
  automationTemplate: 'Automation Template',
  createAutomationRule: 'Create Automation Rule',
  editAutomationRule: 'Edit Automation Rule',
  createAutoRuleFromTemplate: 'Create Automation Rule From Template',
  automationLogs: 'Automation Logs',
  createNewTeam: 'Create New Team',
  inviteUsers: 'Invite Users',
  notificationSettings: 'Notification Settings',
  tokenSettings: 'Token Settings',
  Datadog: 'Datadog',
  trialExpired: 'Trial Expired',
  notAuthorized: 'Not Authorized',
  Unsubscribe: 'Unsubscribe',
  selectCluster: 'selectCluster',
  Email: 'Email',
  resetPassword: 'Reset Password',
  myActionItemsDatadog: 'My Action Items for Datadog',
  reportAbout: 'Report About',
  reportConfiguration: 'Report Configuration',
  reportHistory: 'Report History',
  agentInstallInstructions: 'Agent Install Instructions',
  workingAsAttended: 'Working as intended',
  workingAsAttendedResolution: 'working_as_intended_resolution',
  willNotFixResolution: 'will_not_fix_resolution',
  snoozedResolution: 'snoozed',
  wontFix: "Won't fix",
  snoozed: 'Snoozed',
  Success: 'Success!',
  weeks: 'weeks',
  months: 'months',
  HistoricalResolution: 'HistoricalResolution',
  rightSizer: 'Right Sizer',
  punctuation: {
    empty: ' ',
    colon: ':',
    ellipsis: '...',
    exclamation: '!',
    dash: '-',
    percent: '%',
    plural: 's',
    asterisk: '*',
  },
  noTranslate: {
    cpu: 'cpu',
    cpuRaw: 'cpuRaw',
    cpuCost: 'cpuCost',
    memory: 'memory',
    memoryRaw: 'memoryRaw',
    memoryCost: 'memoryCost',
    networkReceiveCost: 'networkReceiveCost',
    networkTransmitCost: 'networkTransmitCost',
    storageCapacityCost: 'storageCapacityCost',
    current: 'current',
    historical: 'historical',
    monthly: 'monthly',
    weekly: 'weekly',
    hourly: 'hourly',
    daily: 'daily',
    new: 'new',
    fixed: 'fixed',
    resolved: 'resolved',
    high: 'high',
    critical: 'critical',
    clusterOverview: 'cluster-overview',
    every4Hours: 'every 4 hours',
    hours: 'hours',
    minutes: 'minutes',
    avgUsage: 'averageUsage',
    allStatuses: 'All Statuses',
    allTypes: 'All Types',
    allControlIds: 'All Control IDs',
    baseDate: '0001-01-01T00:00:00Z',
    cluster: 'cluster',
    clusters: 'clusters',
    kind: 'kind',
    kinds: 'kinds',
    namespace: 'namespace',
    workload: 'workload',
    workloads: 'workloads',
    container: 'container',
    topWorkloads: 'top-workloads',
    default: 'default',
    namespaces: 'namespaces',
    nsa: 'nsa',
    efficiency: 'efficiency',
    Efficiency: 'Efficiency',
    AdmissionRequests: 'AdmissionRequests',
    green: 'green',
    requests: 'requests',
    limits: 'limits',
    auto: 'auto',
    rejected: 'rejected',
    redirPath: 'redir-path',
    redir: 'redir',
    blank: 'blank',
    value: 'value',
    capitalize: 'capitalize',
    costsPadding: '0px 8px',
    domain: 'domain',
    horizontal: 'horizontal',
    Profile: 'Profile',
    from: 'from',
    sticky: 'sticky',
    currentCluster: 'current-cluster',
    topClusters: 'top-clusters',
    namespacesGrouped: 'namespaces-grouped',
    namespacesUngrouped: 'namespaces-ungrouped',
    kubeSystems: 'kube-systems',
    topWorkloadsCluster: 'top-workloads-cluster',
    topNamespaces: 'top-namespaces',
    receiveBytes: 'resources.networkReceiveBytes',
    costReceiveBytes: 'costs.actual.networkReceive',
    transmitBytes: 'resources.networkTransmitBytes',
    costTransmitBytes: 'costs.actual.networkTransmit',
    diskBytes: 'resources.storageCapacityBytes',
    costDiskBytes: 'costs.actual.storageCapacity',
    repoPadding: '0.5rem 1.5rem',
    onSubmit: 'onSubmit',
    authLogin: 'auth:login',
    captcha: 'captcha',
    on: 'on',
    self: '_self',
    ssoOrg: 'sso-organization',
    register: 'register',
    failure: 'failure',
    confirmPassword: 'confirmPassword',
    settings: 'settings',
    eighteenREM: '18rem',
    mailToSales: 'mailto:sales@fairwinds.com',
    transparent: 'transparent',
    info: 'info',
    smallBottomPadding: '0 0 2px 0',
    summary: 'summary',
    addNetworkAndStorage: 'addNetworkAndStorage',
    addNetworkAndStorageTrue: '&addNetworkAndStorage=true',
    link: 'link',
    zeroPercent: '0%',
    warn: 'warn',
    medium: 'medium',
    publicRepo: 'public-repository',
    centaurus: 'centaurus',
    round: 'round',
    allImages: 'all-images',
    policyMapping: 'policy-mapping',
  },
  numbers: {
    twentyfive: '25',
  },
  textStyling: {
    xs: 'xs',
    md: 'md',
    medium: 'medium',
    lg: 'lg',
    xl: 'xl',
    thin: 'thin',
    bold: 'bold',
    xsBottom: 'bottomXS',
    smBottom: 'bottomSM',
    medBottom: 'bottomMD',
    lgBottom: 'bottomLG',
    regular: 'regular',
    link: 'link',
    default: 'default',
    capitalize: 'capitalize',
    white: 'white',
    primary: 'primary',
    dropdown: 'dropdown',
    darkGray: 'darkGray',
  },
  ariaRoles: {
    rowgroup: 'rowgroup',
    row: 'row',
    presentation: 'presentation',
  },
  general: {
    name: 'Name',
    Yes: 'Yes',
    No: 'No',
    at: 'at',
    by: 'by',
    on: 'on',
    of: 'of',
    for: 'for',
    with: 'with',
    Add: 'Add',
    Next: 'Next',
    day: 'day',
    days: 'days',
    Day: 'Day',
    Days: 'Days',
    Clear: 'Clear',
    Week: 'Week',
    Month: 'Month',
    none: 'None',
    True: 'True',
    False: 'False',
    Showing: 'Showing',
    available: 'available',
    Available: 'Available',
    pending: 'pending',
    Loading: 'Loading',
    createTicket: 'Create Ticket',
    Labels: 'Labels',
    Label: 'Label',
    Project: 'Project',
    Reporter: 'Reporter',
    Assignee: 'Assignee',
    noData: 'no data available',
    installAgent: 'Install Agent',
    pleaseTryAgain: 'Please try again.',
    Settings: 'Settings',
    Repository: 'Repository',
    Repositories: 'Repositories',
    Organization: 'Organization',
    organization: 'organization',
    team: 'team',
    cluster: 'cluster',
    Cluster: 'Cluster',
    Clusters: 'Clusters',
    Admission: 'Admission',
    report: 'report',
    Reported: 'Reported',
    reportedBy: 'Reported By',
    billedCostGreaterThan: 'billedCostGreaterThan',
    billedCostGreaterThanLabel: 'Cost greater than',
    networkingLessThanInMB: 'networkingLessThanInMB',
    networkingLessThanInMBLabel: 'Networking less than (MB)',
    None: 'None',
    na: 'N/A',
    notSet: 'Not Set',
    month: 'month',
    pageReadOnly: 'This page is read-only.',
    Pass: 'Pass',
    Fail: 'Fail',
    PassiveFail: 'Passive Fail',
    PassiveFailure: 'passive_failure',
    Blocked: 'Blocked',
    showingNumber: 'Showing # of',
    results: 'results',
    Submit: 'Submit',
    CPU: 'CPU',
    GB: 'GB',
    Memory: 'Memory',
    mostRecent: 'Most Recent Data',
    thirtyDayAvg: '30 Day Average',
    Node: 'Node',
    Nodes: 'Nodes',
    Limits: 'Limits',
    Limit: 'Limit',
    Requests: 'Requests',
    Cost: 'Cost',
    TotalClusterCost: 'Total Cluster Cost',
    Total: 'Total',
    others: 'others',
    Search: 'Search',
    Types: 'Types',
    Cancel: 'Cancel',
    Release: 'Release',
    Description: 'Description',
    Version: 'Version',
    selection: 'selection',
    Resolve: 'Resolve',
    Snooze: 'Snooze',
    Unsnooze: 'Unsnooze',
    Assign: 'Assign',
    Unassign: 'Unassign',
    SeeSimilarItems: 'See Similar Items',
    EditList: 'Edit List',
    ShareList: 'Share List',
    CreateTicket: 'Create Ticket',
    ViewTicket: 'View Ticket',
    UnlinkTicket: 'Unlink Ticket',
    UnlinkTicketConfirmation: 'Are you sure you want to unlink this ticket?',
    appTitle: 'Fairwinds Insights | Kubernetes configuration validation software',
    appDescriptionContent: 'Proactively Check and Prioritize Kubernetes Configuration Fixes',
    bug: 'Bug',
    unavailable: 'Unavailable',
    selectAll: 'Select All',
    bugCategory: 'Bug Category',
    ticketAssociated: 'There is already an associated ticket for the Action Item(s)',
    cantCreateTicketForFixed: 'Cannot create a ticket for a fixed or resolved Action Item',
    cantCreateTicketForResolvedVulnerability: 'Cannot create tickets for resolved vulnerabilities',
    cantCreateTicketForList: 'Cannot create a ticket for a list when all action items are resolved or fixed',
    additionalActions: 'Additional Actions',
    Export: 'Export',
    issue: 'Issue',
    organizations: 'organizations',
    fetchingError: 'Error loading data. Please try again.',
    home: 'Home',
    noDataAvailable: 'No Data Available',
    noDataToDisplay: 'No data to display',
    notEnoughData: 'Not enough data',
    dataOn: 'Data on',
    allClusters: 'All Clusters',
    newCluster: 'New Cluster',
    seeAll: 'See All',
    lastUpdated: 'Last Updated',
    exportCSV: 'Export to CSV',
    healthScore: 'Health Score',
    advancedFilters: 'Advanced Filters',
    filtering: 'Filtering',
    critical: 'critical',
    high: 'high',
    medium: 'medium',
    low: 'low',
    efficiency: 'Efficiency',
    reliability: 'Reliability',
    security: 'Security',
    confirm: 'Confirm',
    ellipsisIcon: 'ellipsis icon',
    downloadCSV: 'Download CSV',
    printIcon: 'Print icon',
    print: 'Print',
    admin: 'admin',
    viewer: 'viewer',
    editor: 'editor',
    CopiedToClipboard: 'Copied to Clipboard',
    noActionItemsSelected: 'Please select Action Items',
    message: 'Message',
    AppGroup: 'App Group',
  },
  columnTitles: {
    reportType: 'Report Type',
    Configuration: 'Configuration',
    CI: 'CI',
    Admission: 'Admission',
    Agent: 'Agent',
    Title: 'Title',
    Category: 'Category',
    Severity: 'Severity',
    Severities: 'Severities',
    Report: 'Report',
    actionItems: 'Action Items',
    EligibleAutoFixCount: 'Fixable',
    Trends: 'Trends',
    Branches: 'Branches',
    Name: 'Name',
    Image: 'Image',
    Namespace: 'Namespace',
    Cluster: 'Cluster',
    ResourceKind: 'Resource Kind',
    ResourceName: 'Resource Name',
    Vulnerabilities: 'Vulnerabilities',
    Ticket: 'Ticket',
    Assignee: 'Assignee',
    Resolution: 'Resolution',
    SnoozedUntil: 'Snoozed until',
    FirstSeen: 'First Seen',
    LastReported: 'Last Reported',
    Message: 'Message',
    Status: 'Status',
    ResolvedAdmissionMessage: "This action item won't block in future requests as it was resolved as: ",
    ResolvedRepositoryMessage: "This action item won't block in future scans as it was resolved as: ",
  },
  ariaLabels: {
    nodeHexbinChart: "Chart displaying each node's capacity limits and requests",
    lineChart: 'Line chart displaying the #chartName over time',
    clusterBulletChart: 'Chart displaying the different limits, requests, capacity and usages between clusters',
    costsTable: 'table of costs breakdown by aggregators and filters',
    scaleDropdown: 'Chart scale options',
    timeperiodDropdown: 'Chart timeperiod options',
    dataDropdown: 'Chart cpu or memory option',
    qosDropdown: 'Chart qos options',
    settingsOption: 'Current or Historical Settings option',
    containerDropdown: 'Chart container options',
    resourcesPerPodChart: 'Line chart displaying the resource usage over time',
    topOrgIssuesChart: 'Chart displaying the top org issues',
    topClusterIssuesChart: 'Chart displaying the top cluster issues',
    topClusterIssueDropdown: 'Top Cluster Issues Date Select',
    topActionItemsIssuesChart: 'Chart displaying the top issues for action items',
    topImpactedImagesChart: 'Chart displaying the top impacted images',
    topImpactedPackagesChart: 'Chart displaying the top impacted packages',
    topCVEsChart: 'Chart displaying the top vulnerabilities',
    topImpactedWorkloadsChart: 'Chart displaying the top impacted workloads',
    topImpactedReposChart: 'Chart displaying the top improved repositories',
    topRepoIssuesChart: 'Chart displaying the top issues for this repository over time',
    costsByNamespaceSelect: 'Costs by Namespace time period Select',
    costsByNamespaceChart: 'Costs by namespace chart',
    capacityCostChart: 'Cost trend over time',
    repoTrendLineChart: 'Trend chart over time displaying the number of action items over the past 2 months',
    healthScoreTimelineChart: 'Chart displaying timeline of the health score',
    healthScoreTimelinePeriodSelect: 'Health score time period select',
    healthScoreTimelineReportSelect: 'Health score report select',
    healthScoreDonutChart: 'Health Score chart with a given grade of $healthGrade',
    repoSeveritiesPieChart: 'Breakdown of the number of severities for this repository',
    severityPieChart: 'Chart displaying a breakdown of severities',
    reportDonutChart:
      'Overall progress chart displaying the number of checks implemented, that need implementation, and that have not been implemented.',
    costsDonutChart: 'Pie chart for the costs for the time period $dates is',
    allowedDomainTable: 'add an allowed domain input',
  },
  reportTypes: {
    soc2: 'SOC 2',
    iso27001: 'ISO 27001',
    hipaa: 'HIPAA',
    nsa: 'NSA Hardening Guidance',
    allTypes: 'All Types',
  },
  checkStatus: {
    passing: 'Passing',
    needsEvidence: 'Needs Evidence',
    implemented: 'Implemented',
    notImplemented: 'Not Implemented',
    notStarted: 'Not Started',
    notInScope: 'Not in Scope',
    failing: 'Failing',
  },
  constants: {
    configureReport: 'Configure a report',
    resolveFirstActionItem: 'Resolve first Action Item',
    setupAlertPreferences: 'Set up alert notifications preferences',
    connectRepo: 'Connect repository',
    setupTeam: 'Set up team',
  },
  reportOptions: {
    topIssues: 'Top Issues',
    issuesSeverity: 'Issues by Severity',
    issuesCluster: 'Issues by Clusters',
  },
  dateFormats: {
    LLL: 'LLL', // output: August 16, 2018 8:02 PM
    monthAndTime: 'MM/DD/YYYY [at] h:mm A', // 08/16/2023 at 8:02 PM
  },
  dateOptions: {
    lastDay: 'Last Day',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    pastDay: 'Past Day',
    pastWeek: 'Past Week',
    pastMonth: 'Past Month',
    today: 'Today',
    dayAgo: 'day ago',
    daysAgo: 'days ago',
    monthAgo: 'month ago',
    monthsAgo: 'months ago',
    yearAgo: 'year ago',
    yearsAgo: 'years ago',
  },
  navigation: {
    loginWithSSO: 'Login with SSO',
    allClusters: 'All Clusters',
    allRepos: 'All Repositories',
    allImages: 'All Images',
    allPolicies: 'All Policies',
    allRules: 'All Rules',
    allItems: 'All Items',
    allVulns: 'All Vulnerabilities',
    Overview: 'Overview',
    Clusters: 'Clusters',
    addOns: 'Add Ons',
    rbac: 'RBAC',
    admissionController: 'Admission Controller',
    installHub: 'Install Hub',
    Lists: 'Lists',
    userInformation: 'User Information',
    notifications: 'Notifications',
    Password: 'Password',
    teamManagement: 'Team Management',
    Integrations: 'Integrations',
    tokens: 'Tokens',
    SSO: 'SSO',
    dangerZone: 'Danger Zone',
    opaTemplates: 'OPA Policy Templates',
    admissionRequests: 'Admission Requests',
    opaWizard: 'OPA Policy Wizard',
    Capacity: 'Capacity',
    Workloads: 'Workloads',
    RightSizing: 'Right-Sizing',
    Costs: 'Costs',
    ruleWizard: 'Rule Wizard',
    logs: 'Logs',
    ActionItems: 'Action Items',
    Efficiency: 'Efficiency',
    AdmissionRequests: 'Admission',
    Vulnerabilities: 'Vulnerabilities',
    vulnsImageDetail: 'Vulnerabilities Image Detail',
    vulnsCluster: 'Vulnerabilities Cluster',
    vulnsImageDetailCluster: 'Vulnerabilities Image Detail Cluster',
    Repositories: 'Repositories',
    Compliance: 'Compliance',
    Compare: 'Compare',
    Policy: 'Policy',
    Region: 'Region',
    Automation: 'Automation',
    Profile: 'Profile',
    Admin: 'Admin',
    userSettings: 'User Settings',
    logOut: 'Log Out',
    Organizations: 'Organizations',
    Settings: 'Settings',
    Help: 'Help',
    Demo: 'Demo',
    Documentation: 'Documentation',
    FAQ: 'FAQ',
    statusPage: 'Status Page',
    Collapse: 'Collapse',
    Centaurus: 'Centaurus',
    privacyPolicy: 'Privacy Policy',
    openSource: 'Open Source',
    termsConditions: 'Terms and Conditions',
    clusterConfigureAlertMessage: 'This cluster has not been configured yet, redirecting to the Install Hub',
    loginSSO: 'Login with SSO',
    reports: 'Reports',
    stickyTitle: 'Keep the current option selected as you navigate throughout the site',
    summary: 'Summary',
    AppGroups: 'App Groups',
    policyMapping: 'Policy Mapping',
  },
  qosRecs: {
    Critical: 'Critical',
    Guaranteed: 'Guaranteed',
    Burstable: 'Burstable',
    BurstablePlus: 'Burstable+',
    Limited: 'Limited',
  },
  severities: {
    critical: 'critical',
    Critical: 'Critical',
    high: 'high',
    High: 'High',
    medium: 'medium',
    Medium: 'Medium',
    low: 'low',
    Low: 'Low',
    none: 'none',
  },
  jobTitles: {
    jobTitle: 'Job Title',
    devOps: 'Dev Ops',
    engLeader: 'Engineering Leader',
    Architect: 'Architect',
    Security: 'Security',
    Ceo: 'CEO/Founder',
    productDev: 'Product Development',
  },
  integrationDetails: {
    slackDesc: 'Communication tool that helps notify you of changes within your system.',
    datadogDesc: 'Providing monitoring of servers, databases, tolls and services.',
    githubDesc: 'Internet hosting for code and version control using Git.',
    jiraDesc: 'Issue tracking for agile project management.',
    pagerdutyDesc: 'Alerts and incident management for issues with infrastructure security and reliability.',
    azureDesc: 'Collaboration software for software development.',
  },
  reportDetails: {
    polaris: 'Identify Kubernetes workload configuration issues',
    trivy: 'Scan containers for known vulnerabilities',
    goldilocks: 'Get suggested CPU and memory settings for each of your workloads',
    opa: 'Apply custom policies to Kubernetes resources',
    kubeBench: 'Check your cluster against the CIS benchmark',
    nova: 'Discover Helm charts that are out of date',
    pluto: 'Discover resources that are using deprecated APIs',
    rbacReporter: 'Keep track of roles and bindings',
    kubeHunter: 'Hunt for security weaknesses inside your cluster',
    prometheusMetrics: 'Collects Prometheus metrics to provide more accurate cost estimates',
    workloads: 'Gather metadata about each of your workloads',
    admission: "Prevent creation of Kubernetes resources that don't adhere to your policies",
    rightSizer: 'Dynamically set requests and limits using Insights right-sizing recommendations',
    falco: 'Detect intrusions and security events in your cluster',
    cloudcosts: 'Ingest billing information from your cloud provider, like AWS or GCP',
    kyverno: 'Convert background scan Policy Reports into Action Items',
  },
  hubOverview: {
    nonOwner: 'Only owners are allowed to install and uninstall Agents. ',
    permissiveRBAC: ' require permissive RBAC profiles.',
    readyToInstall: 'Ready to Install',
  },
  admissionController: {
    numberChart: 'Number',
    percentChart: 'Percentage',
    inNamespace: 'in namespace',
    seeActionItem: 'See itemsCount Action Item(s)',
    Timeline: 'Timeline',
    noAdmissionController: 'There are no results to display because Admission Controller is not set up at this time.',
    noData: 'We have no data for the past day. Please select a different time range.',
    noResults: 'There were no results for your search',
    setUpAdmission: 'Set up the Admission Controller to see current trends',
    setUpAgent: 'Set Up Agent',
    noDataChangeTimeRange: 'Not enough data to display, please change your time range',
    serviceAccount: 'ServiceAccount?',
    noActionItems: 'No Action Items for this Admission Request',
    chartAriaLabel: 'Chart displaying the number of admission request failures or passes over the past 2 months',
  },
  contactUs: {
    title: 'Contact Us',
    noCaptcha: 'No Captcha',
    errorGettingCaptcha: 'There was an error getting captcha. Please try again.',
    errorSendingMessage: 'There was an error sending your message.',
    from: 'contact-us',
    Subject: 'Subject',
    help: 'How can we help?',
    helpError: 'This field is not allowed to be empty',
    Captcha: 'Captcha',
    emailSent: 'Email Sent!',
    confirmTitle: 'Thanks for reaching out.',
    responseSubtitle: 'We will respond back as soon as we can.',
    helpfulLinks: 'Here are some helpful links in the meantime:',
  },
  efficiency: {
    saveViewAdded: 'The view has been saved successfully',
    savedViewNameRequired: 'Saved view name is required',
    savedViewName: 'Saved View Name',
    selectedFilters: 'Selected Filters',
    selectedAggregators: 'Selected Aggregators',
    saveView: 'Save View',
    newSaveView: 'New Saved View',
    dateRange: 'Date Range',
    savedViewDeleted: 'The saved view has been deleted successfully',
    deleteSavedViews: 'Delete Saved Views',
    avgHourlyCost: 'Avg Hourly Cost',
    totalDailyCost: 'Total Daily Cost',
    installPrometheusForData: 'Install Prometheus to see data',
    installPrometheus: 'Install Prometheus',
    noCostDataToDisplay: 'No cost data to display',
    consistent: 'Consistent',
    proportional: 'Proportional',
    installPrometheusReport: 'Install the Prometheus Metrics report for more accurate resource data',
    installWorkloads: 'Install workloads report to see data',
    Usage: 'Usage',
    notEnoughData: 'Not enough data',
    clusterResources: 'Cluster Resources',
    clusterComparison: 'Cluster Comparison',
    requestsAllWorkloads: 'requests set by all workloads',
    limitsAllWorkloads: 'limits set by all workloads',
    acrossAllNodes: 'across all nodes',
    usedByAllWorkloads: 'being used by all workloads',
    monthlyClusterCost: 'monthly cluster cost',
    dailyClusterCost: 'daily cluster cost',
    totalResources: 'total resources',
    Utilization: 'Utilization',
    Filtering: 'Filtering',
    applyChanges: 'Apply Changes',
    totalCostOver: 'Total Cost Over',
    lastSyncedPrometheus: 'Last Synced Prometheus:',
    prometheusNotReported: 'Prometheus has not reported for',
    prometheusNotInstalled: 'Prometheus not installed',
    lastSyncedWithCloudCosts: 'Last Synced with Cloud Costs:',
    lastSyncedWithAWS: 'Last Synced with AWS:',
    cloudCostsNotInstalled: 'Please install Cloud Costs for more accurate costs',
    cluster: 'cluster',
    namespace: 'namespace',
    kind: 'kind',
    workload: 'workload',
    Workload: 'Workload',
    qualityOfService: 'Quality of Service',
    Kind: 'Kind',
    Pod: 'Pod',
    Container: 'Container',
    container: 'container',
    Namespace: 'Namespace',
    workloadLabels: 'Workload Labels',
    namespaceLabels: 'Namespace Labels',
    podLabels: 'Pod Labels',
    avgPods: 'Average Pods',
    memoryBilled: 'Memory Billed',
    cpuBilled: 'CPU Billed',
    billedCost: 'Billed Cost',
    recommendedCost: 'Recommended Cost',
    costDifference: 'Cost Difference',
    costEfficiency: 'Cost Efficiency',
    memoryLimits: 'Memory Total Limits (All Pods)',
    memoryRecommendedLimits: 'Memory Total Recommended Limits (All Pods)',
    memoryRequests: 'Memory Total Requests (All Pods)',
    memoryRecommendedRequests: 'Memory Total Recommended Requests (All Pods)',
    memoryAvgUsage: 'Memory Average Usage',
    memoryBilledCost: 'Memory Billed Cost',
    memoryRecommendedCost: 'Memory Recommended Cost',
    memoryCostDifference: 'Memory Cost Difference',
    cpuLimits: 'CPU Total Limits (All Pods)',
    cpuRecommendedLimits: 'CPU Total Recommended Limits (All Pods)',
    cpuRequests: 'CPU Total Requests (All Pods)',
    cpuRecommendedRequests: 'CPU Total Recommended Requests (All Pods)',
    cpuAvgUsage: 'CPU Average Usage',
    cpuBilledCost: 'CPU Billed Cost',
    cpuRecommendedCost: 'CPU Recommended Cost',
    cpuCostDifference: 'CPU Cost Difference',
    advancedFilters: 'Advanced Filters',
    betaText:
      'You’re currently using a beta version of this page. Please make sure the insights-agent is on version 2.8.0 or later for the most accurate data. We will continue to add new functionality to this interface, and we expect to have a fully-functioning feature in the coming months. If you have any feedback, please',
    contactUs: ' contact us!',
    totalBilled: 'Total Billed Cost',
    totalRecCosts: 'Total Recommendation Cost',
    totalDiffCosts: 'Total Recommendation Difference',
    avgBilled: 'Average Billed Cost',
    totalCostsTimePeriod: 'Total Costs for Time Period',
    totalCostsRemainder: 'Total Costs for Remainder',
    totalWithSavings: 'Net Cost with Savings',
    totalSavingsAvailable: 'Total Savings Available',
    costBy: 'Cost by',
    noDataToDisplay: 'No data to display',
    oneMoreDaysAgo: '1 or more days ago',
    noQosSelected: 'no qos selected',
    noQoS: 'No QoS',
    minUsage: 'Min Usage',
    maxUsage: 'Max Usage',
    avgUsage: 'Average Usage',
    recLimits: 'Recommended Limits',
    recRequests: 'Recommended Requests',
    actualLimits: 'Actual Limits',
    actualRequests: 'Actual Requests',
    resourcesPerPod: 'Resources Per Pod',
    totalCostRemainder: 'Total Cost for Remainder',
    allContainers: 'All Containers',
    donutTooltip:
      'Total Cost (center) reflects the cost for the entire Organization over the selected timeframe. Filtering cost results will dynamically update the Billed Cost and Idle Cost sections of this graph.',
    Current: 'Current',
    Recommended: 'Recommended',
    Difference: 'Difference',
    Requests: 'Requests',
    Limits: 'Limits',
    avgCPUPerPod: 'Avg CPU cost per pod',
    avgMemoryPerPod: 'Avg memory cost per pod',
    costWithRec: 'Cost with Recommendation',
    copyIcon: 'copy icon',
    copiedValues: 'Copied Recommended Values',
    costsDetails: 'Costs Details',
    totals: 'Totals',
    defaultQoS: 'Default Quality of Service',
    QoS: 'Quality of Service',
    ResourcesPerContainer: 'Resources Per Container',
    customizingNumbers: 'Customizing the Numbers',
    costCustomizingSubtitle:
      'Cost data is currently being ingested from your cloud bill. To set custom values, remove the Cloud Costs report from your Insights Agent.',
    instanceType: 'Instance Type',
    costPerNode: 'Cost per Node',
    CPU: 'CPUs',
    GB: 'Memory',
    CPUPerHour: 'Cost per CPU per Hour',
    CPUPerHourTooltip:
      'You can infer this cost from your hourly node cost. By default, we allocate half the node cost to memory and half to CPU.',
    GBPerHour: 'Cost per GB per Hour',
    GBPerHourTooltip:
      'You can infer this cost from your hourly node cost. By default, we allocate half the node cost to memory and half to CPU.',
    DiskPerMonth: 'Cost per GB of Disk per month',
    DiskPerMonthTooltip:
      'The storage cost associated with PersistentVolumes. This number may depend on your storage class.',
    GBPerTransmit: 'Cost per GB of Network Transmit',
    GBPerTransmitTooltip: 'The cost associated with network sent out of workloads.',
    GBPerReceived: 'Cost per GB of Network Received',
    GBPerReceivedTooltip: 'The cost associated with network received by workloads.',
    saveSettings: 'Save Settings',
    unableRetrieveSettings: 'Sorry, we were unable to retrieve your settings. Please try again or create your settings',
    settingsChanged: 'Settings changed!',
    unableProcessChanges: 'We were unable to process your changes, please try again.',
    unableResetDefaults: 'There was a problem resetting your default settings',
    cloudCostsTooltip:
      'Enable the cloud-costs report to update these numbers automatically using your cloud bill. See our documentation for more information',
    resetToDefaults: 'Reset to Default Costs',
    optimistic:
      "The optimistic method assumes workloads are packed into nodes with minimal waste. This is a good option if your workloads are very small relative to node size, or if you've spent time optimizing the CPU/memory profile of your nodes.",
    conservative:
      'The conservative method takes into account the fact that a memory-intensive workload might cause some CPU to go unused, and vice versa. This is a good option if you have CPU- or memory-intensive workloads, and have not spent time optimizing the CPU/memory profile of your nodes.',
    critical: 'Mission-critical workloads that should be over-provisioned to maximize reliability.',
    guaranteed: 'Production workloads that can withstand some normal noise.',
    burstable: 'Workloads that should prioritize cost savings over reliability.',
    limited: 'Workloads that should be given as little resources as they need to operate.',
    burstable_plus: 'Production workloads with increased limits for scaling events.',
    costPerNodeHour: "Cost per node-hour is the rate you're charged by your cloud provider",
    costPerCPU:
      "Cost per CPU-hour is the cost attributed to each CPU being used. By default, we divide a node's cost evenly between its CPUs and its Memory",
    costPerGB:
      "Cost per GB-hour is the cost attributed to each GB of memory being used. By default, we divide a node's cost evenly between its CPUs and its Memory",
    costError: 'Value is required to be a number with a maximum of 4 decimals',
    numberError: 'Value must be 1 or greater',
    setCustomNumbers: 'Set custom numbers',
    selectACluster: 'Please select a cluster from the dropdown above to change your cost settings',
    selectInstance: 'Select Instance Type',
    cloudProvider: 'Cloud Provider',
    needInstanceType: 'An instance type needs to be selected',
    guaranteedQoS: 'guaranteed',
    instanceDropdown: 'instance type dropdown',
    cloudDropdown: 'cloud provider dropdown',
    kubeSystem: 'kube-system',
    costOverTime: 'Cost Over Time',
    dailyAverage: '24 Hour Average',
    quickViews: {
      label: 'Quick views',
      topClusters: 'Top clusters',
      topNamespacesGrouped: 'Top namespaces grouped',
      topNamespacesUngrouped: 'Top namespaces ungrouped',
      topWorkloads: 'Top workloads',
      kubeSystemByCluster: 'kube-system by cluster',
      topNamespaces: 'Top namespaces',
    },
    networkReceived: 'Network Received',
    networkReceivedCost: 'Network Received Cost',
    networkSent: 'Network Sent',
    networkSentCost: 'Network Sent Cost',
    storage: 'Storage',
    storageCost: 'Storage Cost',
    clear: 'Clear',
  },
  freeTier: {
    FreeTierBannerMessage:
      'The free tier of Fairwinds Insights includes 30 days of cost data. For increased historical data, you’ll need to upgrade your license.',
    Upgrade: 'Upgrade',
  },
  clusterOverview: {
    admissionRequests: 'Admission Requests',
    admissionRequestsExistsErrorMsg: 'error_retrieving_admission_requests_exists',
    admissionRequestsCountErrorMsg: 'error_retrieving_admission_requests_count',
    noDataAvailable: 'No Data Available',
    updateAgent: 'Update Agent',
    admissionRequestsEmpty: 'Install the admission controller to see current trends.',
    costsEmpty: 'Set up Prometheus Collector to see current usage trends.',
    issuesThisWeek: 'Issues This Week',
    createdBy: 'Created By',
    insights: 'Insights',
    total: 'total',
    resolved: 'resolved',
    export: 'Export',
    tableView: 'Table View',
    noData: 'No Action Items found. Please check your query, permissions or add Action Items.',
    costsByNamespace: 'Costs by Namespace',
    newActionItems: 'New Action Items',
    fixedActionItems: 'Fixed Action Items',
    resolvedActionItems: 'Resolved Action Items',
    criticalActionItems: 'Critical Action Items',
    highActionItems: 'High Action Items',
    fixedTimeline: 'Fixed Action Items Timeline',
    newTimeline: 'New Action Items Timeline',
    resolvedTimeline: 'Resolved Action Items Timeline',
    criticalTimeline: 'Critical Action Items Timeline',
    highTimeline: 'High Action ItemsTimeline',
    allNamespaces: 'All Namespaces',
    allReports: 'All Reports',
    noClusterTopIssues: 'Could not retrieve top issues',
    clusterInfo: 'Cluster Info',
  },
  automatedCheck: {
    additionalEvidence: 'Additional Evidence',
    additionalEvidencePlaceholder: 'Provide any additional evidence here...',
    automated: 'automated',
    failingClusters: 'Failing Clusters',
    failingRepositories: 'Failing Repositories',
    failingResources: 'Failing Resources',
    lastReported: 'Last Reported',
    manual: 'manual',
    manuallyResolved: 'Manually Resolved',
    notInstalled: 'Not Installed',
    notStarted: 'not_started',
    passingClusters: 'Passing Clusters',
    passingRepositories: 'Passing Repositories',
    passingResources: 'Passing Resources',
    policy: 'Policy',
    refreshEvidence: 'Refresh Evidence',
    successfulRefresh: 'Evidence Successfully Refreshed',
    updatedBy: 'Updated By',
    updateReport: 'Update Report',
    viewActionItems: 'View Action Items',
    acceptEvidence: 'Accept Evidence',
    automationDetails1: 'This automated check detects #report_type Action Items',
    automationDetails2: 'with event type #event_types',
  },
  vulnerabilities: {
    cluster: 'cluster',
    clusters: 'clusters',
    clustersHeader: 'Clusters',
    lastScanned: 'Last Scanned',
    hasNotBeenScanned: 'Has not been scanned',
    packages: 'Packages',
    recommendedTag: 'Recommended Tag',
    riskReduction: 'Risk Reduction',
    repositories: 'Repositories',
    severity: 'Severity',
    severity_lowercase: 'severity',
    title: 'Title',
    vulnerabilities: 'Vulnerabilities',
    workloads: 'Workloads',
    errorTopImpactedImages: 'Could not retrieve vulnerabilities top impacted images',
    errorTopImpactedPackages: 'Could not retrieve vulnerabilities top impacted packages',
    errorTopCVEs: 'Could not retrieve vulnerabilities top cves',
    errorVulnSeverities: 'Could not retrieve vulnerabilities severities',
    ShowResolvedOnly: 'Show Resolved Only',
    SyncedDateTitle: 'Last Synced Trivy',
    NoneSyncedDateTitle: 'Install Trivy to see data',
    imageId: 'imageId',
    baseImage: 'Base Image',
  },
  compliance: {
    reportName: 'Report Name',
    nameWrongFeedback: 'Report name has to be between 1 - 64 characters',
    createReport: 'Create Report',
    deleteReport: 'Delete Report',
    editReport: 'Edit Report',
    ownerRoleRequired:
      'You need to be an owner of this organization to view and edit compliance reports. Please ask your Insights administrator to make you an owner to access this page.',
    updatedReport: 'Report Successfully Updated',
    unsuccessfulUpdate: 'Sorry, we were unable to add your evidence, please try again',
    noReport: 'Sorry, we were unable to retrieve your report, please try again',
    allStatuses: 'All Statuses',
    allTypes: 'All Types',
    allControlIds: 'All Control IDs',
    noChecks: 'Sorry, there are no checks that match your search, please try again',
    title: 'Compliance Checks',
    subtitle: 'All of these checks can be exported into a PDF report.',
    download: 'Download Full Report',
    noChecksForStandard: 'Sorry, we do not have any checks for this standard',
  },
  repository: {
    autoFixedIssues: 'issue(s) can be auto fixed',
    autoScan: 'Auto-Scan',
    autoScanAriaLabel: 'Auto-Scan switch',
    autoScanLogs: 'Auto-Scan Logs',
    branch: 'branch',
    branches: 'branches',
    createPRModalEmpty: 'There is no data',
    createPRModalTitle: 'Select Action Items to include in pull request',
    createPullRequest: 'Create Pull Request',
    failureToCreatePullRequest: 'Failure to create pull requests',
    fileName: 'File Name',
    listOfBranches: 'List of Branches',
    noCodeScanActionItems: 'There is no auto-fixed action item',
    pleaseSelectActionItems: 'Please select action items',
    processPRModalContent:
      'A fix PR is being created in $repository_name for the following, this process will take a few minutes. A link to GitHub will appear soon.',
    processPRModalTitle: 'Create pull request',
    pullRequestCreated: 'Pull request created in',
    reRunAutoscan: 'Re-Run Auto-Scan',
    repository: 'repository',
    resourceName: 'Resource Name',
    search: 'Search',
    title: 'Title',
    tryAgain: 'Try Again',
    tryAgainPRModalContent:
      'We were unable to create a pull request in $repository_name. You can try reconnecting your GitHub account.',
    tryAgainPRModalTitle: 'Create pull request',
    viewPRModalContent: 'A fix PR was created in $repository_name for the following Action Items:',
    viewPRModalTitle: 'Create pull request',
    viewPullRequest: 'View Pull Request',
    errorRetrievingRepos: 'Could not retrieve improved repositories',
    errorRetrievingRepoTopIssues: 'Could not retrieve top issues for the repository',
    errorRetrievingSeverities: 'Could not retrieve severities',
    noScan: 'No Scan',
    AddRepoModalTitle: 'CI Installation',
    AddRepoModalContent:
      'You can scan your Infrastructure-as-Code repositories with Insights by connecting in one of two ways:',
    AddRepoModalContent1:
      "Connecting with GitHub - This is the easiest way to get started. You can grant Insights access to specific repositories and we'll automatically scan every new pull request and commit.",
    AddRepoModalContent2:
      "Connecting Manually - This involves adding a script to your existing CI/CD pipelines. We recommend this method if you're not using GitHub.",
    ConnectGithub: 'Connect GitHub',
    ConnectGithubManually: 'Connect Manually',
    ConnectGithubManuallyFirstStep:
      '1. Add this file at the root of your repository, customizing it to your needs: fairwinds-insights.yaml',
    CIProviders: 'CI Providers',
    ConnectGithubManuallyFirstStepContent: `
    options:
      organization: ~orgName~

      # You may need to set this to 'main'
      baseBranch: master

    # These images will be scanned for vulnerabilities
    images:
      docker:
        - nginx:1.18-alpine
        - quay.io/~orgName~/my-app:$CI_HASH

    # These manifests will be scanned for configuration issues
    manifests:
      yaml:
        - ./deploy/manifests/
        - ./main.deployment.yaml
      helm:
        - name: prod
          path: ./deploy/chart
          values:
            foo: bar
  `,
    ConnectGithubManuallySecondStep:
      '2. Add the following environment variable as a secret in your CI settings: FAIRWINDS_TOKEN',
    ConnectGithubManuallyThirdStep: '3. Add the following command to your CI configuration: ',
    TeamManagementModalTitle: 'All Repositories',
    TeamManagementModalSubTitle: 'Choose how you want Insights to work with these GitHub repositories:',
    TeamManagementModalSubTitle1:
      'Auto-Scan - Insights will automatically scan Infrastructure-as-Code with each new pull request and commit to the repository',
    TeamManagementModalSubTitle2:
      'Issue Creation - Insights will allow users to create issues in this repository to track Action Items',
    CannotLoadRepositories: 'Cannot load repositories',
    CannotUpdateRepository: 'Cannot update repository',
    NoRepositories: 'There are no repositories.',
    issueCreation: 'Issue Creation',
    issueCreationAriaLabel: 'Issue creation switch',
    resolution: 'resolution',
    historicalResolution: 'historicalResolution',
    historicalResolutionLabel: 'Historical Resolution',
  },
  rbac: {
    Admin: 'Admin',
    adminDesc: 'Able to view secrets & escalate permissions',
    Write: 'Write',
    writeDesc: 'Able to create, update and delete objects',
    Read: 'Read',
    readOnly: 'Read-only',
    roleName: 'Role Name',
    Subjects: 'Subjects',
    Verbs: 'Verbs',
    apiGroups: 'API Groups',
    Resources: 'Resources',
    caption: 'table of rbac rules',
    Permissions: 'Permissions',
  },
  addonsSection: {
    noReports: 'No reports found. Try',
    reinstallAgent: 'reinstalling the Agent',
    outOfDate: 'Some add-ons are out of date',
    upToDate: 'Up to Date',
    addons: 'Add-ons',
  },
  automationRule: {
    action: 'Action',
    admissionController: 'Admission Controller',
    all: 'All',
    cluster: 'Cluster',
    confirmDeletionOfRule: 'Confirm deletion of rule',
    context: 'Context',
    createCustomRule: 'Create Custom Rule',
    createFromTemplate: 'Create From Template',
    createOneNow: 'Create one now',
    deleteRuleFailed: 'There was an issue trying to delete the rule. Please try again.',
    deleteRuleSuccess: 'Automation rule $ruleName deleted successfully!',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enableExecutionLogsToggle: 'Enable Execution Logs Toggle',
    loggingInfo: 'Turn logs on for 24 hours by going into an individual rule and toggling logging on.',
    loggingIsOff: 'Logging is off',
    loggingIsOn: 'Logging is on',
    newRule: 'New Rule',
    noRulesFound: 'No rules found!',
    onlyOwner: 'Only owners are allowed to modify automation rules.',
    or: 'or',
    readOnly: 'This page is read-only.',
    readTheDocumentation: 'read the documentation',
    report: 'Report',
    repository: 'Repository',
    rule: 'Rule',
    ruleAlreadyExists: 'The rule $ruleName already exists',
    ruleDescription: 'Rule Description',
    ruleName: 'Rule Name',
    rules: 'Rules',
    rulesWizard: 'Rules Wizard',
    saveRule: 'Save Rule',
    search: 'Search',
    templates: 'Templates',
    toggleRuleFailed: 'There was an issue trying to update the policy. Please try again.',
    toggleRuleSuccess: 'Policy $ruleName has been $ruleStatus',
    updateRule: 'Update Rule',
  },
  addOns: {
    noReports: 'No reports found. Try',
    reinstallAgent: 'reinstalling the Agent',
    outOfDate: 'Out of Date',
    upToDate: 'Up to Date',
  },
  register: {
    company: 'Company',
    firstName: 'First Name',
    lastName: 'Last Name',
    country: 'Country',
    countryRequired: 'Country is required',
    mainReason: "What's the main reason you're signing up?",
    isRequired: 'This is required',
    hearAboutUs: 'How did you hear about us?',
    signUpError: 'There was a problem with signing you up. Please try again.',
    news: 'News / Blog / Social Media',
    webinar: 'Webinar',
    wordOfMouth: 'Word of mouth',
    Other: 'Other',
    consistency: 'Enforce consistency',
    configIssues: 'Help developers fix issues',
    automate: 'Automate compliance and/or security',
    optimize: 'Optimize costs',
    companyName: 'Company Name',
    name: 'Name',
  },
  region: {
    selectCountry: 'Select a Country',
  },
  newOrganization: {
    createOrganization: 'Create Organization',
    freeTierMessage: "You're already an owner of an organization that is in the free tier or an ongoing trial.",
    name: 'Name',
    newOrganization: 'New Organization',
    organizationID: 'Organization ID',
    please: 'Please',
    salesEmail: 'sales@fairwinds.com',
    selfHostedMessage:
      'Self Hosted Insights is limited to a single organization name and that has already been configured.',
    title: 'Create an organization to start adding teammates and Kubernetes clusters',
    toLearnAbout: 'to learn about upgrade options, or contact',
    visitOurWebsite: 'visit our website',
    organizationNameRequired: 'You must specify an organization name',
  },
  openSources: {
    license: 'License',
    title: 'Use of Open Source Software',
    viewOnGithub: 'View on Github',
  },
  organizations: {
    admin: 'admin',
    askYourOrg: 'Ask your organization owner to add $userEmail as a member, or ',
    createANewOrg: 'Create a new organization',
    myOrganizations: 'My Organizations',
    new: 'New',
    viewer: 'viewer',
    youAreNotAMemberOfAnyOrg: "You're not a member of any organizations.",
  },
  createTicketModal: {
    additionalFields: 'Additional Fields',
    addMoreFields: 'Add more additional fields',
    addTicketField: 'Add Ticket Field',
    configHasNotBeenSaved: 'Configs have not been saved',
    configSaved: 'Configs saved',
    estimate: 'Estimate',
    issueType: 'Issue Type',
    removeAdditionalField: 'Remove additional field',
    twoHours: '2h',
    issueGroup: 'Issue Group',
    workItemType: 'Work Item Type',
    field: 'Field',
    value: 'Value',
  },
  viewTicketModal: {
    createTicket: 'Create Ticket',
    subTitle: 'A ticket was created in $provider for the following items:',
    viewTicket: 'View Ticket',
    UnlinkTicket: 'Unlink Ticket',
    ticketUnlinked: 'Ticket unlinked successfully',
    errorUnlinkingTicket: 'Error unlinking ticket. Please try again',
    noTicketCreated: 'No tickets were created. Verify if the action items are already resolved or fixed. Also, verify if a ticket was already created for the action items.',
  },
  policy: {
    policyCreated: 'Policy #policyName created successfully!',
  },
  localStorage: {
    organization: 'organization',
  },
  actionItemsPage: {
    filters: {
      Fixed: 'Fixed',
      Resolution: 'Resolution',
      page: 'page',
      pageSize: 'pageSize',
      from: 'from',
      listName: 'listName',
      orderBy: 'orderBy',
      Cluster: 'Cluster',
      Notes: 'Notes',
      ReportType: 'ReportType',
      ResourceContainer: 'ResourceContainer',
      ResourceName: 'ResourceName',
      ResourceKind: 'ResourceKind',
      ResourceNamespace: 'ResourceNamespace',
      ResourceLabel: 'ResourceLabel',
      Title: 'Title',
      EventType: 'EventType',
    },
    bySeverity: 'By Severity',
    topIssues: 'Top Issues',
    topNamespaces: 'Top Namespaces',
    topWorkloads: 'Top Workloads',
    errorRetrievingTopSeverities: 'Could not retrieve top severities',
    errorRetrievingTopWorkloads: 'Could not retrieve top workloads',
    contextMenu: {
      resolutionError: 'Error updating the resolution. Please try again.',
      resolutionSuccess: 'Resolution was successfully updated',
      snoozeSuccess: 'Action Item was $action',
      snoozeError: 'Error $action. Please try again.',
      additionalActions: 'Additional Actions',
      unsnoozed: 'unsnoozed',
      snoozedFor: 'snoozed for 1 $period',
      period: '$period',
      unsnoozingActionItem: 'unsnoozing Action Item',
      snoozingActionItem: 'snoozing Action Item',
      errorAssigning: 'Error updating the assignee. Please try again.',
      actionItemUnassigned: 'Action item has been unassigned',
      actionItemAssigned: 'Action item assigned to $email',
      assignIcon: 'assign icon',
      resolveIcon: 'resolve icon',
      ticketIcon: 'ticket icon',
      similarIcon: 'similar icon',
      action: '$action',
    },
  },
  actionItemsList: {
    createdBy: 'Created by',
    total: 'total',
    fixed: 'fixed',
    resolved: 'resolved',
    new: 'new',
    tableView: 'Table View',
    deleteList: 'Delete List',
    loadActionItems: 'Load Action Items',
    noActionItems: 'No Action Items',
    noActionItemsFound: 'No Action Items found. Please check your query, permissions or add Action Items.',
    errorRetrievingAI: 'Problem retrieving Action Items. Please try again',
    newList: 'New List',
    listName: 'List Name',
    importList: 'Import List',
    nameExists: 'Sorry, that name already exists. Please choose another name',
    actionItemQuery: 'Action Item Query',
    createList: 'Create List',
    noActionItemsForList: 'No Action Items found for this list',
    ticketUnlinked: 'Ticket unlinked successfully',
    errorUnlinkingTicket: 'Error unlinking ticket. Please try again',
  },
  useListsHook: {
    noNameError: 'You need to have a name to create a list.',
    noQueryError: 'You need to have a query to create a list.',
    notAuthorized: 'You are not authorized to create a list. Please speak with the organization owner.',
    addingNewListError: 'Problem adding new list. Please try again',
    wrongQueryFormat:
      'Please make sure your query is in the correct format. CapitalizedFilter=value AND CapitalizedFilter=value',
    listNotFound: "Sorry, looks like we can't find that list. Please try again",
    listDeleted: 'List was successfully deleted',
    unauthorizedListDelete: 'You are not authorized to delete a list. Please speak with the organization owner.',
    errorDeletingList: 'Error deleting list. Please try again',
    unauthorizedEditingList: 'You are not authorized to edit a list. Please speak with the organization owner.',
    unableEditList: 'Unable to update. Make sure your query is in the correct format',
    unableUpdateList: 'Sorry, we were unable to update your list. Please try again',
    listUpdated: 'List was successfully updated',
    unableDeleteActionItem: 'Unable to delete Action Item. Please try again',
    listNameTooLong: 'List names need to be 64 characters or less. Please try again.',
    noListName: 'You must have a list name',
    notEditableList: 'Action items or a query is required to edit this list',
    deletedActionItem: 'Action item deleted!',
    newListCreated: 'List $listName successfully created!',
  },
  orgs: {
    allOrganizations: 'All Organizations',
  },
  unsubscribe: {
    goodbye: 'goodbye!',
    content: 'You have been successfully unsubscribed from email communications.',
    goToHomePage: 'Go to Homepage',
  },
  auth: {
    login: 'Log In',
    signUp: 'Sign Up',
    signIn: 'Sign In',
    forgotPassword: 'Forgot Password',
    learnMore: 'Learn More',
    mainLoginPageDesc: 'Log into Fairwinds Insights, a platform for Kubernetes policy and configuration validation.',
    loginPageDescription: 'Fairwinds Insights user login. Access Kubernetes configuration validation software',
    noSAMLIntegration: 'SAML Integration not found',
    errorSigningIn: 'Error while signing in',
    resetPassword: 'Reset Your Password',
    passwordValidationError: 'Password requires 1 number and 1 symbol and be at least 8 characters',
    otherSignInOptions: 'Other Sign In Options',
    orgName: 'Organization Name',
    samlTitle: 'SAML Single Sign-On',
    enterOrgName: "Enter your organization's name",
    ssoTooltip: 'If you need help, contact your org admin',
  },
  allClusters: {
    error: 'Error loading data. Please try again.',
  },
  newCluster: {
    addNewCluster: 'Add a New Cluster',
    ownerRequired: "You're not an owner for $organizationName. You'll need to ask an owner to create a new cluster.",
    subTitle: "Once you add a cluster, you'll be able to choose which reports you want to install for the cluster",
    clusterIdRequired: 'Please create a name for your cluster',
    createCluster: 'Create Cluster',
    clusterName: 'Cluster Name',
    freeTierMessage:
      "You've reached the Insights free tier cluster limit. You can add more clusters now and we'll be in touch to discuss unlimited access",
  },
  policies: {
    allPolicies: 'All Policies',
    noName: 'No Name',
    Unknown: 'Unknown',
    Default: 'Default',
    Customized: 'Customized',
    cliToCustomize: 'Use Insights CLI to customize these policies',
    syncDate: '$day at',
    policyRemoved: 'Policy $title removed',
    errorNoName: 'Error removing the policy because there is no name assigned',
    errorRemovingPolicy: 'Error removing policy $title',
    createOPA: 'Create OPA Policy',
    lastSynced: 'Customized Policies Last Synced: $syncedDate',
    noPolicies: 'No policies to display',
    opaPolicy: 'OPA Policy',
    wantToDelete: 'Do you want to delete $title',
    Block: 'Block',
    Warn: 'Warn',
    CLI: 'CLI',
    Enabled: 'Enabled',
    eventType: 'Event Type',
    Report: 'Report',
    policyDescription: 'Policy Description',
    Remediation: 'Remediation',
    noDescriptionOPA: 'No description set for OPA policy',
    noRemediationOPA: 'No remediation available for OPA policy',
  },
  selfAssessment: {
    title: 'Self Assessment',
    evidence: 'Evidence',
    evidencePlaceHolder: 'Please document the evidence you are providing here...',
    cancel: 'Cancel',
    completeAssessment: 'Complete Assessment',
  },
  clustersOverview: {
    offline: 'Offline',
    notEnoughData: 'Not Enough Data',
    notInstalled: 'Not Installed',
  },
  userProfile: {
    firstName: 'First Name',
    firstNameRequired: 'Please enter a first name.',
    jobTitle: 'Job Title',
    lastName: 'Last Name',
    lastNameRequired: 'Please enter a last name.',
    updateProfile: 'Update Profile',
    updateYourProfileInformation: 'Update Your Profile Information',
    userDetails: 'User Details',
    password: 'Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    confirmPasswordError: 'New password and confirm password must match.',
    updatePassword: 'Update Password',
    resetPasswordErrorMessage: 'Please logout and try again.',
    resetPasswordSuccessMessage: 'You have successfully changed your password!',
    email: 'Email',
    unsubscribe: 'Unsubscribe',
    update: 'Update',
    subscribe: 'Subscribe',
    subscribeEmailContent:
      'You are not currently subscribed to the email digest. To subscribe, update your preferences below.',
    unSubscribeEmailContent:
      'You are currently subscribed to the email digest. To unsubscribe, update your preferences below.',
    notifications: 'Notifications',
    errorSigningUp: 'Error while signing up. Please try again',
  },
  errorPage: {
    errorCode: '404',
    message: "We can't find the page you're looking for",
    subMessage: `
      The page might have been removed, had its name changed,
      or cannot be accessed by the current user.
    `,
    ohno: 'Oh No!',
    unexpectedErrorOccurred: 'An unexpected error occurred',
    errorCodeText: 'Error Code:',
    helpfulLinks: 'Here are some helpful links instead.',
    home: 'Home',
    docs: 'Docs',
    faqs: 'FAQs',
  },
  admin: {
    Users: 'Users',
    funnelData: 'Funnel Data',
    funnelDataCaption: 'funnel data table',
    allowedDomains: 'Allowed Domains',
    addAllowedDomains: 'Add allowed domains',
    removeDomain: 'Remove Domain',
    allowedDomainsSearch: 'Search for domains',
    allowedDomainsSearchLabel: 'allowed domains search input',
    addAllowedDomainLabel: 'add an allowed domain input',
    allowedDomainCaption: 'allowed domains table',
    selfHostedUsers: 'Self Hosted Users',
    searchForUsers: 'Search for users',
    selfHostedUsersCaption: 'self hosted users table',
    selfHostedInstallations: 'Self Hosted Installations',
    selfHostedInstallationsCaption: 'self hosted installations table',
    Expires: 'Expires',
    Funnel: 'Funnel',
    Count: 'Count',
    ID: 'ID',
    Domain: 'Domain',
    Actions: 'Actions',
    Email: 'Email',
    Name: 'Name',
    Tier: 'Tier',
    trialExpiration: 'Trial Expiration',
    installationUUID: 'Installation UUID',
    Disabled: 'Disabled',
    reenableUser: 'Re-enable this User',
    disableUser: 'Disable this User',
    notInTrial: 'Not in Trial',
    trialExpired: 'Trial Expired',
    inTrial: 'In Trial',
    reenableInstallation: 'Re-enable this Installation',
    disableInstallation: 'Disable this Installation',
    clusterCount: 'Cluster Count',
    agentsInstalled: 'Agents Installed',
    latestReport: 'Latest Report',
    orgTier: 'Org Tier',
    trialStatus: 'Trial Status',
    updateTrial: 'Update Trial',
    orgCreateDate: 'Org Create Date',
    orgMembership: 'Org Membership',
    userCreateDate: 'User Create Date',
    userLastLogin: 'User Last Login',
    userLoginCount: 'User Login Count',
    updateUserType: 'Update User Type',
    canCreateOrg: 'Allowed to create org',
    orgCreatedBy: 'Org Created By',
    removeUserFromOrg: 'Remove User from Organization',
    deleteUser: 'Delete User',
    freeTier: 'Free Tier',
    enterpriseTier: 'Enterprise Tier',
    businessTier: 'Business Tier',
    disabledTier: 'Disabled Tier',
    endTrial: 'End Trial',
    trial14: '14 Day Trial',
    trial30: '30 Day Trial',
    trial60: '60 Day Trial',
    regularUser: 'Regular User',
    superAdmin: 'SuperAdmin',
    superDuperAdmin: 'SuperDuperAdmin',
    Metrics: 'Metrics',
    exportFunnelData: 'Export funnel data to CSV',
    exportUsersCSV: 'Export all users to CSV',
    exportNonAdminCSV: 'Export Non-Fairwinds users to CSV',
    exportReportEvents: 'Export latest report events to CSV',
    downloadReportEvents: 'Download latest report events as a CSV file',
    exportCurrentUsers: 'Export current users to CSV',
    downloadCurrentUsers: 'Download current users as a CSV file',
    selfHostedCodes: 'Self Hosted Codes',
    selfHostedCodesDesc:
      'Below will be the generated installation code. Copy this immediately, it can not be reused and it can not be retrieved again after generation.',
    generateNewCode: 'Generate a new installation Code',
    numberUsers: 'Number of Users',
    numberUsers1Org: 'Number of Users with 1+ Org Membership',
    numberUsersOrgCluster: 'Number of Users with 1+ Org and 1+ Cluster',
    numberUsersOrgClusterData: 'Number of Users 1+ Org and 1+ Cluster w/ Data',
    numberUsersOrgClusterData7D: 'Number of Users with 1+ Org and 1+ Cluster w/ Data in L7d',
    removeUser: 'Are you sure you want to remove user #email',
    fromOrg: 'from organization',
    deleteUserConfirm:
      'Are you sure you want to delete user #email? Deleting a user will remove all theirs memberships and access to Fairwinds Insights.',
  },
  featureFlags: {
    fixLogin: 'fixLogin',
    actionItemsReports: 'actionItemsReports',
    appGroups: 'appGroups',
    policyMappings: 'policyMappings',
    autoFixResourceRecommendations: 'autoFixResourceRecommendations',
  },
  routes: {
    signTermsOfService: 'sign-terms-of-service',
    notFound: 'not-found',
  },
  actionItemsReports: {
    reportStatuses: 'Report Statuses',
    open: 'Open',
    introduced: 'Introduced',
    manuallyResolved: 'Manually Resolved',
    fixed: 'Fixed',
    actionItems: 'Action Items',
    fixedTooltip: 'The number of Action Items your organization fixed each $PERIOD',
    introducedTooltip: 'The number of new Action Items introduced into your clusters each $PERIOD',
    manuallyResolvedTooltip:
      'The number of Action Items your organization marked "will not fix", "working as intended", or "snoozed" each $PERIOD',
    openTooltip: 'The number of unaddressed Action Items that entered your clusters each $PERIOD',
    cumulative: 'Cumulative Items Over Time',
    cumulativeTooltip: 'The all-time total number of Action Items grouped by status for your organization',
    month: 'month',
    day: 'day',
    deleted: 'Deleted',
    deletedTooltip:
      'The number of Action Items associated with resources deleted from your clusters, or deprecated by the Fairwinds team each $PERIOD',
    cumulativeDeletedTooltip:
      'The all-time total number of Action Items for resources that have been deleted from your clusters, or deprecated by the Fairwinds team',
  },
  settings: {
    sso: {
      singleSignOn: 'Single Sign On',
    },
    dangerZone: {
      freeTierBannerMessage:
        'The free tier of Fairwinds Insights includes scanning for two clusters and one repository. For unlimited access to additional clusters or repositories, you’ll need to upgrade your license.',
      notOrgOwnerMessage:
        "You are only allowed to change these settings if you are an owner. Please contact your organization's owner to make changes.",
    },
  },
  topNav: {
    navAriaLabel: 'Secondary nav',
    pinned: 'dropdown-pinned',
    unpinned: 'dropdown-unpinned',
    clusterMenu: 'cluster menu',
    repoMenu: 'repository menu',
    addCluster: 'Add Cluster',
    addRepo: 'Add Repository',
    Settings: 'Settings',
  },
  teamManagement: {
    addAdditionalClusters: 'Add Additional Clusters',
    addAdditionalNamespaces: 'Add Additional Namespaces',
    addAdditionalRepositories: 'Add Additional Repositories',
    blockedAccess: 'Blocked Access',
    blockedClusters: 'Blocked Clusters',
    blockedNamespaces: 'Blocked Namespaces',
    blockedRepositories: 'Blocked Repositories',
    clusters: 'Clusters',
    createANewTeam: 'Create a New Team',
    createTeam: 'Create Team',
    namespaces: 'Namespaces',
    newTeam: 'New Team',
    noClustersAllowed: 'No clusters allowed',
    noClustersBlocked: 'No clusters blocked',
    noNamespacesAllowed: 'No namespaces allowed',
    noNamespacesBlocked: 'No namespaces blocked',
    noRepositoriesAllowed: 'No repositories allowed',
    noRepositoriesBlocked: 'No repositories blocked',
    repositories: 'Repositories',
    subTitleAddNewTeam: 'Create a team to allow users to access specific clusters, namespaces and repositories.',
    teamAccess: 'Team Access',
    teamAlreadyExists: 'Team already exists',
    teamName: 'Team Name',
    teamNameRequired: 'Please input team name',
    teamManagement: 'Team Management',
    inviteUsers: 'Invite Users',
    newUsers: 'New Users',
    subTitle:
      'Each user will be assigned one role and at least one team. A role has a set of permissions associated with it, and a team can view specific clusters and namespaces.',
    enterAValidEmail: 'Enter a valid email',
    team: 'Team',
    role: 'Role',
    ownerAccessTooltip: 'Organization owners are able ,to add new users, manage teams, and delete the organization.',
    inviteAdditionalUsers: 'Invite Additional Users',
    existingUserErrorMessage: 'User is already a member of this organization',
    sendInvites: 'Send Invites',
    inviteUsersFailed: 'Error trying to invite users',
    addMember: 'Add Member',
    teamAccountsTable: 'Team Accounts Table',
    allMembers: 'All Members',
    changeRoleSuccess: 'Role was changed successfully',
    changeRoleFailed: 'Failure to change role',
    deleteMembershipSuccess: 'The member was deleted successfully',
    deleteMembershipFailed: 'Failed to delete the member',
    addNewMemberSuccess: 'The new member was added successfully',
    addNewMemberFailed: 'Failure to add the new member',
    accountsTable: 'Accounts Table',
    action: 'Action',
    all: 'All',
    allAccounts: 'All Accounts',
    email: 'Email',
    inviteUsersTo: 'Invite users to',
    name: 'Name',
    ownerAccess: 'Owner Access',
    owners: 'Owners',
    pending: 'Pending',
    pendingUser: 'Pending User',
    remove: 'Remove',
    standard: 'Standard',
    teams: 'Teams',
    confirmAccountRemovalTitle: 'Confirm account removal',
    confirmAccountRemovalContent:
      'Please confirm that you want to remove the following account from $organization: $email',
    removeAccountSuccess: 'The account was removed successfully',
    removeAccountFailed: 'Failure to remove the account',
    confirmOwnershipChangeTitle: 'Confirm ownership change',
    confirmOwnershipChangeContent1: '$email will be made Owner of the $organization organization.',
    confirmOwnershipChangeContent2: '$email will not be made Owner of the $organization organization.',
    removeAccountTeamSuccess: 'The team was removed from the account',
    removeAccountTeamFailed: 'Failure to remove the team from the account',
    addAccountTeamSuccess: 'The team was added',
    addAccountTeamFailed: 'Failure to add the team',
    settings: 'Settings',
    allTeams: 'All Teams',
    addTeam: 'Add Team',
    confirmTeamRemoval: 'Confirm Team Removal',
    deleteTeamModalContent: 'Please confirm that you want to delete the following team: $TEAM',
    myRole: 'My Role',
    delete: 'Delete',
    allTeamsTable: 'All Teams Table',
    deleteTeamSuccess: 'Team was deleted successfully',
    deleteTeamFailed: 'Failure to delete team',
    updateTeamAccessSuccess: 'Team access was updated successfully',
    updateTeamAccessFailed: 'Failure to update team access',
    lastLogin: 'Last Login',
    allClusters: 'All Clusters',
    allRepositories: 'All Repositories',
    allNamespaces: 'All Namespaces',
  },
  reportHub: {
    About: 'About',
    Configure: 'Configure',
    History: 'History',
    admission: 'admission',
  },
  pillBadge: {
    passing: 'passing',
  },
  datadog: {
    myActionItems: 'My Action Items',
    newestActionItems: 'Newest Action Items',
    datadogActionItems: 'Datadog Action Items',
    noActionItems: 'There are no more action items',
    viewInTable: 'View in Action Items Table',
    actionItemManagement: 'Action Item Management',
    showMore: 'Show more details',
    integrationText:
      'To ensure your Datadog metrics are as accurate as possible, please make sure all clusters are using the latest version of the Insights Agent.',
    estimatesError:
      'We were unable to estimate the impact Insights will have on your Datadog bill. Please ensure the latest Agent is running in all clusters.',
    disconnect: 'Disconnect Datadog',
    connect: 'Connect to Datadog',
    subtitle: 'Send Events and Metrics to Datadog',
    tooltipText: 'This key can be retrieved by logging into Datadog, then visiting Integrations > APIs > API Keys',
    apiKey: 'API Key',
  },
  aggregators: {
    cluster: 'cluster',
    namespace: 'namespace',
    workload: 'workload',
    kind: 'kind',
    eventType: 'eventType',
    reportType: 'reportType',
    title: 'title',
    severity: 'severity',
    category: 'category',
    assigneeEmail: 'assigneeEmail',
    container: 'container',
    status: 'status',
  },
  aggregatedActionItems: {
    aggregatorLabels: {
      title: 'Title',
      cluster: 'Cluster',
      namespace: 'Namespace',
      workload: 'Workload',
      kind: 'Kind',
      eventType: 'Title/Event Type',
      reportType: 'Report Type',
      severity: 'Severity',
      category: 'Category',
      assigneeEmail: 'Assignee Email',
      container: 'Container',
      status: 'Status',
    },
  },
  appGroups: {
    AppGroups: 'App Groups',
    AppGroupsSummary: 'App Groups - Summary',
    AppGroupsReports: 'App Groups - Reports',
    summary: 'Summary',
    reports: 'Reports',
    appGroupsEmptyMessage: 'Add an App Group to make grouping Kubernetes resources easier.',
    errorRetrievingAppGroups: 'Error retrieving app groups',
    errorRetrievingPolicyMappings: 'Error retrieving policy mappings',
    allAppGroups: 'All App Groups',
    addAppGroup: 'Add App Group',
    loading: 'Loading...',
    appGroupsSummaryTable: 'App groups summary table',
    newAppGroup: 'New App Group',
    delete: 'Delete',
    appGroupName: 'App Group Name',
    nameOfAppGroup: 'Name of App Group',
    matchResources: 'Match Resources',
    nameOfThing: 'Name of thing',
    clusters: 'Clusters',
    repositories: 'Repositories',
    namespaces: 'Namespaces',
    namespaceLabels: 'Namespace Labels',
    labels: 'Labels',
    labelsKey: 'labels',
    excludeResources: 'Exclude Resources',
    cancel: 'Cancel',
    saveAppGroup: 'Save App Group',
    appGroupNameTooltip: 'This field cannot be edited later.',
    matchResourcesTooltip:
      'Select which resources should be included in this app group. A resource must match at least one of the selectors in each field to be included. If nothing is selected, all resources are matched. A selector can be an exact match, or have an asterisk (*) at the beginning or end for a suffix/prefix match.',
    kinds: 'Kinds',
    containers: 'Containers',
    tagValueError: 'The value format must be x=y',
    excludeResourcesTooltip:
      'Use this section to filter out any resources that were selected by the "Match Resources" section. The same rules apply.',
    names: 'Names',
    workloadNames: 'Workload Names',
    cannotCreateTheAppGroup: 'Cannot create the app group $APP_GROUP_NAME',
    createAppGroupSuccess: '$APP_GROUP_NAME has been created successfully',
    errorRetrievingAppGroupByName: 'Error retrieving app group by its name',
    cannotUpdateTheAppGroup: 'Cannot update the app group',
    updateAppGroupSuccess: '$APP_GROUP_NAME has been updated successfully',
    deleteAppGroup: 'Delete App Group',
    overrideAppGroup: 'Override App Group',
    deleteAppGroupConfirmationContent: 'Do you want to delete $APP_GROUP_NAME?',
    overrideAppGroupConfirmationContent: 'Do you want to override $APP_GROUP_NAME?',
    deleteAppGroupsSuccess: 'The app group has been deleted successfully',
    cannotDeleteAppGroup: 'Cannot delete the app group',
    updateAppGroup: 'Update App Group',
    seeActionItems: 'See Action Items',
    nameOfResources: 'Name of resources',
    appGroupsTableTooltip: "Use Policy Mappings to link App Groups with specific Policies you'd like to scan for.",
  },
  testLabels: {
    addButton: 'add-button',
  },
  policyMapping: {
    block: 'Block',
    blockBasedOnPolicySettings: 'Block based on policy settings',
    alwaysBlock: 'Always block on these policies',
    neverBlock: 'Never block on these policies',
    scanModeRequired: 'Please select a scan mode',
    scanModeUpdated: 'The scan mode has been updated successfully',
    failedToUpdateScanMode: 'Failed to update the scan mode',
    focusedRecommended: 'Focused (Recommended)',
    appGroups: 'app-groups',
    everything: 'Everything',
    scanAll: 'scan-all',
    scanMode: 'Scan Mode:',
    warningMessage: 'Policy Mappings will not take effect until you enable Focused mode',
    addPolicyMapping: 'Add Policy Mapping',
    createPolicyMappingSuccess: '$POLICY_MAPPING_NAME has been created successfully',
    cannotCreateThePolicyMapping: 'Cannot create the policy mapping $POLICY_MAPPING_NAME',
    newPolicyMapping: 'New Policy Mapping',
    policyMappingTooltip: 'This field cannot be edited later.',
    policyMappingName: 'Policy Mapping Name',
    nameOfPolicyMapping: 'Name of Policy Mapping',
    appGroupsLabel: 'App Groups',
    cancel: 'Cancel',
    savePolicyMapping: 'Save Policy Mapping',
    policies: 'Policies',
    policyMappingNameRequired: 'The policy mapping name is required',
    appGroupsRequired: 'Please provide at least one app group',
    loading: 'Loading...',
    errorRetrievingPolicyMappings: 'Error retrieving policy mappings',
    policyMappingEmptyMessage: 'Please add new policy mappings',
    contexts: 'Contexts',
    agent: 'Agent',
    admission: 'Admission',
    ci: 'CI',
    errorRetrievingPolicyMappingByName: 'Error retrieving policy mapping by its name',
    updatePolicyMapping: 'Update Policy Mapping',
    deletePolicyMapping: 'Delete Policy Mapping',
    deletePolicyMappingConfirmationContent: 'Do you want to delete $POLICY_MAPPING_NAME?',
    deletePolicyMappingSuccess: 'The policy mapping has been deleted successfully',
    cannotDeletePolicyMapping: 'Cannot delete the policy mapping',
    focusedTooltip: 'Scan only the resources and policies defined in App Groups and Policy Mappings.',
    everythingTooltip:
      'Scan all resources using all available policies for maximum coverage. This may generate many Action Items per resource',
    updatePolicyMappingSuccess: '$POLICY_MAPPING_NAME has been updated successfully',
    toggleEnabledPolicyMappingSuccess: '$POLICY_MAPPING_NAME has been $ENABLED successfully',
    cannotUpdateThePolicyMapping: 'Cannot update the policy mapping $POLICY_MAPPING_NAME',
    cannotToggleEnabledPolicyMapping: 'Cannot toggle enabled for policy mapping $POLICY_MAPPING_NAME',
    viewPolicyMapping: 'View Policy Mapping',
    policyTooltip:
      'Select one or more Policies to attach to these App Groups. You can also select an entire group of Policies, like "polaris", or leave this space blank to select all Policies.',
    allPolicies: 'All Policies',
    policyMappingNameTooltip: 'This field cannot be edited later.',
    blockOnPolicyConfigurationTooltip:
      'These policies will block based on configured policy settings. If not explicitly set, it will block on High and Critical severity policies only.',
  },
};
